import styled from "styled-components";
import { ButtonBack, ButtonNext, Dot } from "pure-react-carousel";
import { ReactComponent as EditIcon } from "../../../assets/edit-contract.svg";
import Checkbox from "@material-ui/core/Checkbox";

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const BackButton = styled(ButtonBack)`
  position: static;
  width: auto;
  height: 32px;
  margin-right: 20px;
`;

export const NextButton = styled(ButtonNext)`
  position: static;
  width: auto;
  height: 32px;
  padding: 1px 6px;
`;

export const SliderDot = styled(Dot)`
  padding: 0;
  width: 12px;
  height: 12px;
  background-color: #e2e7e8;
  border-radius: 50%;
  border: none;
  margin: 10px;
`;

export const PreviewTitle = styled.p`
  font-size: 22px;
  font-weight: bold;
  color: #263242;
  margin-top: 0;
  margin-bottom: 42px;
`;

export const PreviewRow = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 36px;
`;

export const PreviewRowLeftSide = styled.div`
  font-size: 16px;
  color: #abb3b5;
`;

export const PreviewRowRightSide = styled.div`
  font-size: 16px;
  color: #263242;
`;

export const AgreementsLink = styled.a`
  cursor: pointer;
  text-decoration: underline;
  color: #526df2;
  font-size: 14px;
  margin-left: 10px;
`;

export const AgreementInformationContainer = styled.div`
  display: flex;
  align-items: flex-end;
`;

export const EditButton = styled(EditIcon)`
  position: absolute;
  right: 1px;
  cursor: pointer;
  z-index: 1000;
`;

export const BankName = styled.div`
  font-size: 12px;
  color: #abb3b5;
  margin-top: 3px;
`;

export const PrivacyPolicyFormContainer = styled.div`
  margin: 24px 0 30px;

  @media (max-width: 768px) {
    margin: 32px 0 24px;
  }
`;

export const CheckboxComponent = styled(Checkbox)`
  color: #4646eb !important;
  padding: 0 !important;
`;

export const CheckboxRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -14px 20px;

  &:last-child {
    margin-bottom: 0;
  }

  & > div {
    margin: 0 14px;
  }
`;
