import React, { ReactElement, useEffect, RefObject, useState } from "react";
import { navigate } from "gatsby";
import { useDispatch } from "react-redux";
import NumberFormat from "react-number-format";
import cn from "classnames";

import { goNextStep, setFormState, setFormValues } from "../../store/actions";
import { RequestState, EnergyTariffValues, ContractForms } from "../../types";
import Preloader from "../SVG/Preloader";
import { Button } from "../Button";

import styles from "./styles.mod.scss";
import { PreviewTitle } from "../Contract/PoliciesForm/styled";

interface EnergyTariffProps extends RequestState<EnergyTariffValues> {
  isPreview?: boolean;
  tariffResultRef?: RefObject<HTMLDivElement>;
}

const defaultBillingAddress = {
  billingFirstName: "",
  billingSurname: "",
  billingZip: "",
  billingCity: "",
  billingCityName: "",
  billingStreet: "",
  billingHouseNumber: "",
  billingEmail: "",
  billingPhoneAreaCode: "",
  billingPhone: "",
};

function EnergyTariff({
  isPending,
  data,
  error,
  isPreview,
  tariffResultRef,
}: EnergyTariffProps): ReactElement {
  const dispatch = useDispatch();
  const onCreteContract = () => {
    if (data?.formValues) {
      dispatch(setFormValues(ContractForms.calculator, { ...data.formValues }));
      dispatch(
        setFormValues(ContractForms.billingAddress, defaultBillingAddress)
      );
      dispatch(
        setFormState(ContractForms.billingAddress, {
          isSubmitting: false,
          isValid: false,
        })
      );
      dispatch(goNextStep("calculator"));
    }

    navigate("/contract/");
  };

  const [open, setOpen] = useState<boolean>(false);

  const labels = {
    estimatedPayment: `Voraussichtlicher Abschlag*`,
    annualPrice: `Jahrespreis*`,
    serviceCost: `Monatliche Grundgebühr*`,
    networkCost: `Monatliche Netzkosten*`,
  };
  const formattedData =
    data?.tariff &&
    Object.entries(data?.tariff).reduce(
      (acc, [key, value]) => ({
        ...acc,
        [key]: (
          <NumberFormat
            value={value}
            displayType={"text"}
            decimalSeparator={","}
            suffix={" €"}
            thousandSeparator={"."}
            decimalScale={2}
            fixedDecimalScale={true}
          />
        ),
      }),
      { ...data?.tariff, ...data.formValues }
    );
  const tariff__bodyItem = cn(
    "d-flex",
    "justify-content-between",
    styles.tariff__bodyItem
  );

  useEffect(() => {
    setOpen(Boolean(isPending) || Boolean(data) || Boolean(error));

    const scrollToRef = () => {
      if (tariffResultRef?.current?.offsetTop) {
        window.scrollTo(0, tariffResultRef?.current?.offsetTop - 80);
      }
    };

    if (formattedData && !isPending && !error) {
      scrollToRef();
    }
  }, [formattedData, isPending, data, error, tariffResultRef]);

  return (
    <div
      className={cn({
        [styles.tariff]: !isPreview,
        [styles.tariff_pending]: Boolean(isPending),
        [styles.tariff_error]: Boolean(error),
        [styles.tariffPreview]: isPreview,
        [styles.tariff_open]: open,
      })}
    >
      {isPending && !error && (
        <div className={styles.tariff__pendingContainer}>
          <Preloader />
        </div>
      )}
      {error && (
        <div className={styles.tariff__errorContainer}>
          <p>Unknown error occurred</p>
          <p>{error.message}</p>
        </div>
      )}
      {formattedData && !isPending && !error && (
        <>
          <div className={styles.tariff__header}>
            {isPreview ? (
              <PreviewTitle style={{ marginBottom: "22px" }}>
                Tarifübersicht
              </PreviewTitle>
            ) : (
              ""
            )}
            <div className={styles.tariff__estimatedPayment}>
              <p className={styles.tariff__title}>{labels.estimatedPayment}</p>

              {(!data?.formValues.street || !data.formValues.houseNumber) && (
                <div className={styles.tariff__highestNetfee}>
                  <p className={styles.tariff__highestNetfeeText}>
                    Achtung, für deine Postleitzahl gibt es unterschiedliche
                    Netzkosten. Bitte gib deine Straße und Hausnummer für eine
                    genauere Berechnung an.
                  </p>
                </div>
              )}

              <p className={styles.tariff__estimatedPerMonth}>
                {formattedData.estimatedPerMonth} / Monat
              </p>
            </div>
            <div
              className={cn(
                "d-flex",
                "justify-content-between",
                "align-items-center",
                styles.tariff__annualPrice
              )}
            >
              <p>{labels.annualPrice}</p>
              <p>{formattedData.annual}</p>
            </div>
            <div className={styles.tariff__annualPriceDescription}>
              bei{" "}
              <NumberFormat
                value={data?.formValues.energyUsage}
                displayType={"text"}
                decimalSeparator={","}
                thousandSeparator={"."}
              />{" "}
              kWh Jahresverbrauch
            </div>
          </div>
          <div className={styles.tariff__body}>
            <div>
              <div className={tariff__bodyItem}>
                <p className={styles.tariff__bodyItemLabel}>
                  {labels.serviceCost}
                </p>
                <p className={styles.tariff__bodyItemValue}>
                  {formattedData.stromee}
                </p>
              </div>
              <div className={tariff__bodyItem}>
                <p className={styles.tariff__bodyItemLabel}>
                  {labels.networkCost}
                </p>
                <p className={styles.tariff__bodyItemValue}>
                  {formattedData.networkCosts}
                </p>
              </div>
              <div className={tariff__bodyItem}>
                <p className={styles.tariff__bodyItemLabel}>
                  Monatlicher Verbrauch{" "}
                  <span className={styles.tariff__bodyItemNowrap}>
                    (
                    <NumberFormat
                      value={data?.tariff?.consumptionPricePerKWh}
                      displayType={"text"}
                      decimalSeparator={","}
                      thousandSeparator={"."}
                      decimalScale={2}
                      fixedDecimalScale={true}
                    />{" "}
                    ct./ kWh)*
                  </span>
                </p>
                <p className={styles.tariff__bodyItemValue}>
                  {formattedData.consumptionPrice}
                </p>
              </div>
            </div>
            <div className={isPreview ? styles.tariff__additionalInfo : ""}>
              <div className={tariff__bodyItem}>
                <p className={styles.tariff__bodyItemLabel}>Mindestlaufzeit</p>
                <p className={styles.tariff__bodyItemValue}>
                  {data?.tariff.minimumTerm}
                </p>
              </div>
              <div className={tariff__bodyItem}>
                <p className={styles.tariff__bodyItemLabel}>Ökostrom</p>
                <p className={styles.tariff__bodyItemValue}>100% Wasserkraft</p>
              </div>
              <div className={tariff__bodyItem}>
                <div className={styles.tariff__bodyItemApp}>
                  <p>stromee App</p>
                  <p className={styles.tariff__bodyItemAppDes}>
                    (inkl. Energiesparfunktion)
                  </p>
                </div>
                <p>kostenlos</p>
              </div>
              <div className={tariff__bodyItem}>
                <p className={styles.tariff__bodyItemLabel}>Preisgarantie**</p>
                <p className={styles.tariff__bodyItemValue}>12 Monate</p>
              </div>
              {isPreview && formattedData?.promoCode?.length && (
                <div className={tariff__bodyItem}>
                  <p className={styles.tariff__bodyItemLabel}>Aktionscode</p>
                  <p className={styles.tariff__bodyItemValue}>
                    {formattedData.promoCodeDescription}
                  </p>
                </div>
              )}
            </div>
          </div>
        </>
      )}
      {!isPreview && (
        <div className={styles.tariff__buttons}>
          <Button
            variant="orange"
            disabled={Boolean(isPending || error)}
            onClick={onCreteContract}
            id="bestellen"
          >
            Bestellen
          </Button>
        </div>
      )}
      {!error && !isPending && (
        <div className={styles.tariff__note}>
          <p className={styles.tariff__noteText}>
            *Inkl. MwSt. Geringfügige Rundungsdifferenzen möglich. <br />{" "}
            **Eingeschränkte Preisgarantie, ausgenommen Steuern und Abgaben.
            Gültig ab Vertragsabschluss.
          </p>
        </div>
      )}
    </div>
  );
}

export default EnergyTariff;
